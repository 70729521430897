import { gql } from '@apollo/client';

export const GET_METRICS = gql`
  query GetMetrics($startDate: Date!, $endDate: Date!) {
    analytics(startDate: $startDate, endDate: $endDate) {
      impressions
      clicks
      revenue
    }
  }
`;

export const GET_REVENUE_HISTORY = gql`
  query GetRevenueHistory($startDate: Date!, $endDate: Date!) {
    analytics(startDate: $startDate, endDate: $endDate) {
      revenueHistory
    }
  }
`;

export const OFFER_ANALYTICS = gql`
  query OfferAnalytics($startDate: Date!, $endDate: Date!, $limit: Int) {
    offerAnalytics(startDate: $startDate, endDate: $endDate, limit: $limit) {
      id
      name
      type
      orders
      revenue
    }
  }
`;

export const OFFER_TYPE_ANALYTICS = gql`
  query OfferTypeAnalytics($startDate: Date!, $endDate: Date!) {
    offerTypeAnalytics(startDate: $startDate, endDate: $endDate)
  }
`;

export const PRODUCT_ANALYTICS = gql`
  query ProductAnalytics($startDate: Date!, $endDate: Date!, $limit: Int) {
    productAnalytics(startDate: $startDate, endDate: $endDate, limit: $limit)
  }
`;
